<template>
  <div class="page-review animate__animated animate__fadeIn pt-4">
    <div v-if="!warningSlothPlus">
      <p class="fs-md text-center mt-4 mb-5">

        ✨Join thousands of happy SlothMovers✨
      </p>

      <!-- Client's Move -->
      <h4
        v-if="services.length == 0"
        style="font-weight: 100"
        class="fs-xl my-5"
      >
        You have not selected anything to update
      </h4>

      <!-- SlothMove Selection -->
      <div
        class="slothmove-selection-section"

      >




        <!-- Summary -->
        <div
          class="box-summary mb-4"
        >
          <div class="d-flex justify-content-between">
            <h4 class="fs-la mb-0 font-weigth-bold">Order Summary</h4>
          </div>
          <hr />

          <div class="d-flex justify-content-between">
            <div class="row">
              <div class="col-12">
                <h5 style="font-weight: 600" class="fs-sm">Moving from</h5>
                <p class="mb-1 fs-sm">
                  {{ moveFromAddress }}
                </p>
                <p class="mb-1 fs-sm">
                  {{ moveFromPostCode }}
                </p>
              </div>
              <div style="margin-top: 10px" class="col-12 pe-1">
                <h5 style="font-weight: 600"class="fs-sm">Moving to</h5>
                <p class="mb-0 fs-sm">{{ dateMove(moveInDate) }}</p>
                <p class="mb-0 fs-sm">
                  {{ moveToAddress }}
                </p>
                <p class="mb-0 fs-sm">
                  {{ moveToPostCode }}
                </p>
              </div>
            </div>
          </div>



          <div style="margin-top: 10px" class="d-flex justify-content-between">
            <h5 style="font-weight: 600"class="fs-sm">Updating</h5>

          </div>
          <div
            class="row"
            v-for="(item, index) in services"
            :key="index"
            style="padding: 4px 2px"
          >
            <div class="logoRev">
              <img :src="`${urlBucket}/${item.icon}`" style="width: 45%;" alt="" srcset=""/>
            </div>
            <div style="width: 55%;margin-left:-13%;margin-top:12px">
              <h4 style="font-size: 17px;font-weight:600" class="fs-xl mb-0">{{ item.name }}</h4>
            </div>
          </div>


          <hr v-if="
            referrer !== 'TaylorRose' &&
            /*!this.isCanopyUtilitiesOnly()  &&*/
            referrer !== 'AddressUpdater' &&
            referrer !== 'FamilyUpdater'
          " />

          <div v-if="
          referrer !== 'TaylorRose' &&
          /*!this.isCanopyUtilitiesOnly()  &&*/
          referrer !== 'AddressUpdater' &&
          referrer !== 'FamilyUpdater'
          "
            style="font-size: 20px;font-weight:600" class="d-flex justify-content-between">
            <p class="fs-la mb-0 font-weigth-bold">Slothmove</p>
            <p class="fs-xl mb-0">£{{ priceOffered.toFixed(2) }}</p>
          </div>
          <p v-if="
            referrer !== 'TaylorRose' &&
            /*!this.isCanopyUtilitiesOnly()  &&*/
            referrer !== 'AddressUpdater' &&
            referrer !== 'FamilyUpdater'
          " style="font-size: 20px;font-weight:600" class="fs-la mb-0 font-weigth-bold">Unlimited Address Updates</p>
        </div>

        <!-- Mover Bonus -->
        <div class="mover-bonus" v-if="referrer !== 'TaylorRose'">
          <div v-if="false">
            <h3 class="">{{ getCurrentMonth() }} Mover Bonus:</h3>
            <div class="d-flex align-items-center mb-3">
              <img :src="require('@/assets/timer.png')" class="logo-timer me-2" alt="" srcset="" />
              <h5 class="fw-bold mb-0" style="color: #ff7a7a">
                Expires: <span style="color: white">{{ counter }}</span>
              </h5>
            </div>
            <p class="mb-4 fs-md">
              If you use SlothMove after the bonus offer expires, you are able to purchase these
              bonuses individually
            </p>

          </div>

          <!-- Checkboxes -->
          <div class="checkboxes mb-4">
            <div class="d-flex align-items-center">
              <p-check
                style="font-size: 22px"
                name="check"
                class="pretty p-icon p-curve p-thick p-jelly stay-join"
                color="primary"
                @change="toggleCheckJoin"
                :value="campaignChecked"
              >
                <div class="state">
                  <i style="margin-left: -67%" class="icon mdi mdi-check"></i>
                </div>
              </p-check>
              <p class="mb-0 ms-3 fs-xs c-pointer" @click="autoClickCheckbox('stay-join')">
                Stay in touch and join the SlothMovement.The latest deals, guides, tips and tricks
                to help you save money and get ahead 🦥
              </p>
            </div>
          </div>

        </div>
      </div>

      <p class="fs-md mt-3" style="color: rgb(198, 74, 69)" v-if="errorTryingSubmit">
        * Something went wrong. Please try again in a minute
      </p>

      <OneOffPayment
        ref="refStripeCheckout"
        :email="email"
        :referrer="referrer"
        :priceOffered="priceOffered"
        v-if="setPaymentStripe"
      >
      </OneOffPayment>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Vue from "vue";
import moment from "moment";
import OneOffPayment from "@/components/OneOffPayment";
import emailjs from "emailjs-com";
import Datepicker from "vuejs-datepicker";
import { navigateTo } from "../router/index.js";
import { tiktokAnalytics } from "../utils/tiktokAnalytics.js";
import {deleteRoute} from "../router/setRoute";

// @ is an alias to /src
export default {
  name: "PageReviewBig8",
  components: { OneOffPayment, Datepicker },
  data() {
    return {
      toggleMoreInfo: false,
      toggleEdit: false,
      services: [],
      waterSelected: [],
      councilSelected: [],
      // Payment
      loading: false,
      isSending: false,
      currentStep: "1",
      slothMoveServices: [],
      vatCalculations: {
        35: {
          taxes: 5.83,
          netValue: 29.17,
        },
        295: {
          taxes: 49.17,
          netValue: 245.83,
        },
      },
      counter: null,
      interval: null,
      setPaymentStripe: false,
      paymentLink: "",
      errorTryingSubmit: false,
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    updateValue(itemName, value) {
      // console.log("updateValue", value);
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    autoGrow(element) {
      let limit = 600; //height limit
      element.style.height = "";
      element.style.height = Math.min(element.scrollHeight, limit) + "px";
    },
    toggleTerms() {
      this.termsChecked = !this.termsChecked;

      // if (this.termsChecked) {
      //   document.getElementById('termsError').innerHTML = ''
      // } else {
      //   document.getElementById('termsError').innerHTML = 'You must agree to our terms and conditions'
      // }
    },
    toggleCheckJoin(e) {
      this.$store.commit("formStore/setField", [`campaignChecked`, e]);
    },
    autoClickCheckbox(className) {
      document.querySelector(`.${className} input[type=checkbox]`).click();
    },
    fillSelectedServices() {
      let arrayTemp = [];

      //Council and Waters


      if ((this.informNewWater && this.newWater) || (this.informOldWater && this.oldWater) || this.informEnergy)
        this.waterSelected.push({ icon: `form/big8/utilities.png`, name: 'Utilities' });

      if ((this.informOldCouncil && this.oldCouncil) || (this.informNewCouncil && this.newCouncil))
        this.councilSelected.push({
          icon: `form/big8/council.png`,
          name: 'Council Tax',
        });


      // DVLA
      if (this.informDVLA) arrayTemp.push({ icon: `form/big8/dvla.png`, name: "DVLA" });

      //NHS
      if (this.informNHS) arrayTemp.push({ icon: `form/big8/nhs.png`, name: "NHS" });

      if (this.informHMRC) arrayTemp.push({ icon: `form/big8/hmrc.png`, name: "HMRC" });

      if (this.informDWP) arrayTemp.push({ icon: `form/big8/dwp.png`, name: "DWP" });


      if (this.informElectoralRoll)
        arrayTemp.push({ icon: `form/big8/electoral.png`, name: "Electoral Roll" });


      if (this.informTVLicense)
        arrayTemp.push({
          icon: "form/big8/tv.png" ,
          name: "TV Licence",
        });





      let arrayForMap = [
        ...this.waterSelected,
        ...this.councilSelected,
        ...arrayTemp
      ].filter((item) => item != "");

      arrayForMap.forEach((item) => {
        try {
          if (!this.services.some((uniqueItem) => uniqueItem.name === item.name)) {
            this.services.push(item);
          }
        } catch (error) {
          // console.error(error)
        }
      });

      // this.services = arrayForMap;

      if (this.services.length == 0) {
        this.$store.commit("setField", [`messageButton`, "Go Back"]);
      }
    },
    isCanopyUtilitiesOnly(){
      let r=false;
      if (this.referrer.includes("anopy")){
        if(!this.informDentist &&
          !this.informDWP &&
          !this.informDVLA &&
          !this.informHMRC &&
          !this.informStudentFinance &&
          !this.informElectoralRoll&&
          !this.informNHS&&
          !this.informEnergy&&
          !this.informBanks&&
          !this.informLoyalty&&
          !this.informCharities&&
          !this.informMobile&&
          !this.informBreakdown&&
          !this.informBonds&&
          !this.informTVLicense&&
          !this.informLottery&&
          !this.informInsurance&&
          !this.informHomecare&&
          !this.informPets&&
          !this.informOpticians){
          r=true;
        }
        return r;
      }
    },
    setPriceForPaymentLink() {
      let client_reference_id = this.email.replaceAll("@", "SLOTat12").replaceAll(".", "SLOTdot13");
      let params = `?prefilled_email=${this.email}&client_reference_id=${client_reference_id}`;

      this.paymentLink =
        /* £50 */
        window.location.hostname == "localhost"
          ? `https://buy.stripe.com/test_3csdQW22m56Sc2kaEH` /* Test */
          : `https://buy.stripe.com/aEU4iy9XTgtw5kA3cc`; /* Live */

      if (this.priceOffered == 295) {
        this.paymentLink =
          window.location.hostname == `localhost`
            ? `https://buy.stripe.com/test_7sIeV0gXg56Sd6o004` /* Test */
            : `https://buy.stripe.com/7sIcP4c61eloeVabIK`; /* Live */
      }

      this.paymentLink += params;
    },
    stripeCheckoutPayment() {
      if (this.services.length == 0) {
        deleteRoute("/councils");
        navigateTo("/services");
        return;
      }

      // if (this.termsChecked == undefined) {
      //   this.termsChecked = false;
      //   return;
      // }

      this.setPriceForPaymentLink();
      // window.open(this.paymentLink, '_blank'); return; /* for test payment links */

      if (this.isSending /* || !this.termsChecked */) return;

      // this.setPaymentStripe = true;
      // this.$refs.refStripeCheckout.submit(); //<-for tests

      this.updateValue("premium", true);

      const collectedData = { ...this.formattedFormResults };

      // return;

      if (
        this.freeUpdate ||
        this.referrer == "TaylorRose" ||
        /*this.isCanopyUtilitiesOnly() ||*/
        this.referrer == "The Bunch" ||
        this.referrer == "AddressUpdater" ||
        this.referrer == "FamilyUpdater"
      ) {
        this.$store.commit("setField", [`messageButton`, "Completing..."]);
        this.$store.dispatch("submitForm");

        return;
      }

      if (this.termsChecked) {
        this.isSending = true;

        fetch(`${this.baseURL}/processMove/pre`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            sessionId: "",
            collectedData,
          }),
          body: JSON.stringify({
            data: {
              sessionId: "",
              collectedData,
            },
          }),
        })
          .then((res) => {
            //Cookies
            this.setLargeCookie("userdata", JSON.stringify(this.stateComplete));
            //Cookies


            if (this.referrer == "tiktok") {
              tiktokAnalytics(this.email,'CompletePayment', this.priceOffered);
            }

            /* Redirect to stripe */
            if (res.status == 200) {
              if ( this.ownedLead) {

                console.log('Payment link with iFrame',JSON.stringify({ stripe: this.paymentLink }));

                window.top.postMessage(JSON.stringify({ stripe: this.paymentLink }), "*");

              } else {
                window.location = this.paymentLink;
              }
            } else {
              throw new Error("Error");
            }

            // this.isSending = false;
            // this.$refs.refStripeCheckout.submit();
          })
          .catch((error) => {
            console.error(error);
            this.isSending = false;
            this.errorTryingSubmit = true;
            // window.location = this.paymentLink;

            // this.$refs.refStripeCheckout.submit();
            // this.setPaymentStripe = false;
          });
      }
    },
    formatDate(date) {
      return moment(date).format("Do MMMM YYYY");
    },
    dateMove(dateParam) {
      const date = moment(dateParam).format("Do MMMM YYYY");
      return moment(dateParam).isValid() ? date : "";
    },
    toggleEditMove() {
      this.toggleEdit = !this.toggleEdit;
    },
    referrerValidation() {
      return (
        this.referrer == "BillingBetterBB" ||
        this.referrer == "TaylorRose" ||
        this.referrer == "The Bunch"
      );
    },
    getCurrentMonth() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const date = new Date();
      const currentMonth = date.getMonth();
      return months[currentMonth];
    },
    counterMonthMoverBonus() {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      // Refresh the counter every second
      this.interval = setInterval(() => {
        let now = new Date().getTime();

        // Calculate the time difference between now and start date
        let distance = lastDay.getTime() - now;

        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000); //optional

        // result
        this.counter = `${days} days, ${hours} hours, ${minutes} minutes` /* , seconds ${seconds} [optional] */;

        if (now >= lastDay.getTime()) {
          clearInterval(this.interval);
          // this.counter = "end";
        }
      }, 1000);
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    setLargeCookie(name, value, days = 1) {
      var maxChunkSize = 4000;
      var numChunks = Math.ceil(value.length / maxChunkSize);
      for (var i = 0; i < numChunks; i++) {
        var start = i * maxChunkSize;
        var end = start + maxChunkSize;
        var chunk = value.slice(start, end);
        this.setCookie(name + i, chunk, days);
      }
    },
  },
  mounted() {
    if (!this.email) {
      navigateTo("/home");
      return;
    }

    if (this.referrer == "TaylorRose" /*|| this.isCanopyUtilitiesOnly() */) {
      this.updateValue("priceOffered", 0);
    }/*else if (this.referrer.includes("anopy")&&!this.isCanopyUtilitiesOnly()){
      this.updateValue("priceOffered", 35);
    }*/

    this.updateValue("warningSlothPlus", false);
    this.slothMoveServices = [
      {
        price: 295,
        description:
          "SlothMove Address updates are just the beginning. Our hassle-busting technologies save you from boring life admin so you can enjoy life in the easy lane.",
        features: [
          {
            name: "SlothMove",
            description: "Unlimited SlothMove address updates for you and your family",
            icon: 1, ///form/icons/
            worth: "50",
            color: "#20a7d7",
          },
          {
            name: "SlothGuard",
            description:
              this.newResidentialStatus == "I own my home"
                ? "Receive an alert if someone tries to commit title fraud on your property"
                : "We'll make sure your rental deposit is protected properly and setup",
            icon: 2,
            worth: "60",
            color: "#d0056b",
          },
          {
            name: "SlothUtility",
            description: "Update gas, electricity, and water readings by whatsapp or email",
            icon: 3,
            worth: "20",
            color: "#ffe859",
          },
          {
            name: "SlothProtect",
            description: "Know if your personal data appears ina data breach or the dark web",
            icon: 4,
            worth: "25",
            color: "#59ff83",
          },
          {
            name: "Parking Ticket Figther",
            description:
              "We'll help you appeal any parking fine you get to maximise your chances of success",
            icon: 5,
            worth: "65",
            color: "#59fff5",
          },
          // {
          //   name: "SlothQueue",
          //   description: "Never wait on hold with companies, well waitin the queue for you",
          //   icon: 6,
          //   worth: "35",
          //   color: "#ffa459",
          // },
          {
            name: "Life Admin",
            description: "More technologies to save time, beat bureaucracy, and save money",
            icon: 7,
            worth: "400",
            color: "#fb58ea",
          },
        ],
        class: "plus",
        // type: "Homeowner",
        icon: "form/logos/slot-superman.png",
      },
      {
        price: 35,
        description:
          "The classic, award-winning change of address service. Update everyone and track the progress of your updates in your own dashboard",
        items: ["SlothMove change of address", "Dashboard updates", "Live chat support"],
        class: "normal",
        // type: "Normal",
        icon: "form/logos/slot-profile.png",
      },
    ];

    this.$store.commit("setField", ["titleNavbar", `${this.firstName}'s SlothMove`]);

    this.counterMonthMoverBonus();

    this.$store.commit("setField", ["routeAssigned", null]);

    this.fillSelectedServices();

    //  console.log({ ...this.formattedFormResults });

    const validatePage = () => {
      this.stripeCheckoutPayment();
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      // console.log();
      if (
        e.key == "ArrowLeft" &&
        document.activeElement.tagName !== "INPUT" &&
        document.activeElement.tagName !== "TEXTAREA"
      ) {
        this.$router.back();
      }

      if (
        e.key == "ArrowRight" &&
        document.activeElement.tagName !== "INPUT" &&
        document.activeElement.tagName !== "TEXTAREA"
      ) {
        validatePage();
      }
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "freeUpdate",
      "firstName",
      "lastName",
      "email",
      "baseURL",
      "urlBucket",
      "moveToAddress",
      "moveFromAddress",
      "moveToPostCode",
      "moveFromPostCode",
      "moveInDate",
      "moveOutDate",
      "informNewCouncil",
      "informNewWater",
      "informOldCouncil",
      "informOldWater",
      "informDentist",
      "informDWP",
      "informChildBenefit",
  "informAttendanceAllowance",
  "informDisabilityLivingAllowance",
  "informPensionCredits",
  "informPersonalIndependencePayment",
  "informStatePension",
      "informDVLA",
      "informHMRC",
      "informStudentFinance",
      "informElectoralRoll",
      "informEnergy",
      "informLoyalty",
      "informCharities",
      "informMobile",
      "informBreakdown",
      "informHomecare",
      "informPets",
      "informNHS",
      "informSpam",
      "informBonds",
      "informTVLicense",
      "informLottery",
      "informBroadbandMedia",
      "informInsurance",
      "informOpticians",
      "oldCouncil",
      "newCouncil",
      "oldWater",
      "newWater",
      "pensionSelection",
      "mobileSelection",
      "selectedBanks",
      "selectedGyms",
      "selectedCharities",
      "selectedLoyalties",
      "insurancesSelection",
      "dentistName",
      "priceOffered",
      "slothMoveSelection",
      "cardOptionSelected",
      "moveOutFlag",
      "selectedEnergySupplier",
      "selectedGasSupplier",
      "selectedHomecare",
      "selectedBreakdown",
      "selectedOpticians",
      "pets",
      "termsChecked",
      "campaignChecked",
      "timeSaved",
      "newResidentialStatus",
      "isPlus",
      "warningSlothPlus",
      "informBanks",
      "suggestedCompanies",
      "ownedLead"
    ]),
    ...mapGetters(["stateComplete"]),
    ...mapGetters("formStore", ["formattedFormResults"]),
  },
  watch: {},
};
</script>
<style lang="scss">
.page-review {
  .oops-card {
    position: relative;
    border-radius: 35px;
    background-color: #f5fcff;
    padding-top: 190px !important;
    margin-top: 110px;
    margin-bottom: 30px;

    .container-banner {
      position: absolute;
      left: 0%;
      top: -90px;
      display: flex;
      justify-content: center;
    }

    button {
      border-radius: 10px;
      border: none;
      padding: 10px 30px;
      text-align: center;
      text-decoration: none;
      background-color: #00d54f;

      .spinner-border {
        --bs-spinner-width: 1.5rem !important;
        --bs-spinner-height: 1.5rem !important;
      }
    }
  }

  textarea {
    width: 100%;
    height: 20px;
    resize: none;
    outline: none;
    min-height: 20px;
    overflow: hidden;
    border: transparent;
  }

  .vdp-datepicker {
    color: black;

    input {
      width: 100% !important;
    }
  }

  .container-banner {
    position: relative;
    text-align: center;
    color: white;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .container-preview-shield {
    position: relative;
    border: 2px solid #22a8d6;
    border-radius: 8px;

    .container-icon-shield {
      position: absolute;
      left: 0%;
      top: -25px;
      display: flex;
      justify-content: center;

      .icon {
        background-color: #1a1d29;
        border-radius: 15px;
      }
    }
  }

  .boxes {
    border-radius: 6px;
    border: 1px solid white;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  @media screen and (min-width: 600px){
    .logoRev{
      width: 25%;
    }
  }
  @media screen and (max-width: 600px){
    .logoRev{
      width: 45%;
    }
  }

  .squares {
    text-align: center;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    box-shadow: 0 1px 7px #0b2d9633;
    height: 168px;

    @media screen and (min-width: 50px) and (max-width: 700px) {
      /*height: calc(100vw - 168px);*/
      height: 100%;
    }

    img {
      width: 90%;
    }
  }

  .container-sloth-title {
    display: flex;
    align-items: center;

    .line {
      flex-grow: 1;
      height: 0;
      border-top: 3px dashed white;
    }
  }

  .mini-cards-selection {
    .col {
      cursor: pointer;
      max-width: 190px;
      outline: 3px solid #cecece;
      border-radius: 30px;
      background-color: black;
    }

    .normal {
      outline: 5px solid #23a9d7;
    }

    .plus {
      outline: 5px solid #f7ff67;
    }

    .most-popular {
      display: inline-block;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 30px;
      background-color: #ffebff;
      color: #212534;
      p {
        font-size: 8px;
      }
    }
  }

  .slothmove-selection {
    background-color: black;
    border-radius: 30px;
    margin-top: 140px;

    .description {
      background-color: #2e2c32;
      border-radius: 10px;
      text-align: center;
    }

    .main-icon-container {
      position: relative;
      padding-top: 90px !important;

      .icon {
        position: absolute;
        left: 0%;
        top: -122px;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    .select-button {
      border-radius: 10px;
      border: none;
      color: black;
      padding: 10px 30px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      opacity: 1;
      transition: opacity 0.2s;
      letter-spacing: 1.7px;
    }
  }

  .slothmove-selection.normal {
    outline: 5px solid #23a9d7;

    .select-button {
      background-color: #23a9d7;
    }

    .select-button:hover {
      background-color: #23a9d7;
      opacity: 0.8;
    }
  }

  .slothmove-selection.plus {
    outline: 5px solid #f7ff67;

    .select-button {
      background-color: #f7ff67;
    }

    .select-button:hover {
      background-color: #f7ff67;
      opacity: 0.8;
    }

    .worth {
      background-color: #2e2c31;
      border-radius: 3px;
      padding: 0px 5px;
      font-size: 10px;
      margin-left: 8px;
    }
  }

  /*
  .slothmove-selection.selected {
    border: 3px solid #04b125;
    .select-button {
      background-color: #04b125;

      p {
        color: #f5fcff;
      }
    }
  }
  */

  .months-minimum {
    display: flex;
    align-items: center;
    margin-top: 35px;

    .line {
      flex-grow: 1;
      height: 0;
      border-top: 1px solid white;
    }
  }

  .box-summary {
    padding: 1.4rem !important;
    padding-top: 1.9rem !important;
    padding-bottom: 1.2rem !important;
    background-color: white;
    border-radius: 20px;
    color: black;
    hr {
      margin: 1.5rem 0;
    }
  }

  .logo-timer {
    width: calc(1.275rem + 0.3vw);
  }

  .boxes-free {
    border-radius: 15px;
    background-color: black;
    padding: 16px;
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important;
  }

  .free-badge {
    background-color: #800080;
    padding: 6px;
    color: #ffbfff;
    border-radius: 6px;
  }

  .minicard {
    border-radius: 15px;
    background-color: #f5fcff;
    margin-bottom: 40px;
  }

  .box-happens {
    background-color: #fff;
    border-radius: 7px;
    color: black;
  }

  .minicard-2 {
    border-radius: 15px;
    background-color: #f5fcff;
    margin-bottom: 40px;

    span {
      cursor: pointer;
      padding-top: 2px;
      padding-bottom: 3px;
      padding-right: 8px;
      padding-left: 8px;
      background-color: #b4b4b4;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }

    span:hover {
      background-color: #23a9d7;
    }

    span.current {
      background-color: #23a9d7;
    }

    img {
      width: 40%;
      height: 130px;
    }
  }

  @media screen and (min-width: 50px) and (max-width: 500px) {
    .minicard-2 {
      img {
        width: 40%;
        height: 100px;
      }
    }
  }

  .faqs {
    .expand:before {
      font-size: 51px;
      line-height: normal;
      padding-right: 5px;
    }

    .faq {
      margin-bottom: 15px;
      background-color: black;
      border-radius: 20px;

      h4 {
        padding-left: 22px;
      }
      p {
        padding: 0px 22px 22px 22px;
        line-height: 23px;
      }
      /*
      padding-left: 20px;
      padding-bottom: 20px;
      */
    }
  }

  .pretty {
    border-radius: 5px;
    margin-right: 0px;

    .state label:before {
      border-color: #bdc3c7;
      background-color: white;
    }
  }

  .more-info {
    text-align: justify;
  }
}
</style>
