var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-review animate__animated animate__fadeIn pt-4"},[(!_vm.warningSlothPlus)?_c('div',[_c('p',{staticClass:"fs-md text-center mt-4 mb-5"},[_vm._v(" ✨Join thousands of happy SlothMovers✨ ")]),(_vm.services.length == 0)?_c('h4',{staticClass:"fs-xl my-5",staticStyle:{"font-weight":"100"}},[_vm._v(" You have not selected anything to update ")]):_vm._e(),_c('div',{staticClass:"slothmove-selection-section"},[_c('div',{staticClass:"box-summary mb-4"},[_vm._m(0),_c('hr'),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"fs-sm",staticStyle:{"font-weight":"600"}},[_vm._v("Moving from")]),_c('p',{staticClass:"mb-1 fs-sm"},[_vm._v(" "+_vm._s(_vm.moveFromAddress)+" ")]),_c('p',{staticClass:"mb-1 fs-sm"},[_vm._v(" "+_vm._s(_vm.moveFromPostCode)+" ")])]),_c('div',{staticClass:"col-12 pe-1",staticStyle:{"margin-top":"10px"}},[_c('h5',{staticClass:"fs-sm",staticStyle:{"font-weight":"600"}},[_vm._v("Moving to")]),_c('p',{staticClass:"mb-0 fs-sm"},[_vm._v(_vm._s(_vm.dateMove(_vm.moveInDate)))]),_c('p',{staticClass:"mb-0 fs-sm"},[_vm._v(" "+_vm._s(_vm.moveToAddress)+" ")]),_c('p',{staticClass:"mb-0 fs-sm"},[_vm._v(" "+_vm._s(_vm.moveToPostCode)+" ")])])])]),_vm._m(1),_vm._l((_vm.services),function(item,index){return _c('div',{key:index,staticClass:"row",staticStyle:{"padding":"4px 2px"}},[_c('div',{staticClass:"logoRev"},[_c('img',{staticStyle:{"width":"45%"},attrs:{"src":`${_vm.urlBucket}/${item.icon}`,"alt":"","srcset":""}})]),_c('div',{staticStyle:{"width":"55%","margin-left":"-13%","margin-top":"12px"}},[_c('h4',{staticClass:"fs-xl mb-0",staticStyle:{"font-size":"17px","font-weight":"600"}},[_vm._v(_vm._s(item.name))])])])}),(
          _vm.referrer !== 'TaylorRose' &&
          /*!this.isCanopyUtilitiesOnly()  &&*/
          _vm.referrer !== 'AddressUpdater' &&
          _vm.referrer !== 'FamilyUpdater'
        )?_c('hr'):_vm._e(),(
        _vm.referrer !== 'TaylorRose' &&
        /*!this.isCanopyUtilitiesOnly()  &&*/
        _vm.referrer !== 'AddressUpdater' &&
        _vm.referrer !== 'FamilyUpdater'
        )?_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"font-size":"20px","font-weight":"600"}},[_c('p',{staticClass:"fs-la mb-0 font-weigth-bold"},[_vm._v("Slothmove")]),_c('p',{staticClass:"fs-xl mb-0"},[_vm._v("£"+_vm._s(_vm.priceOffered.toFixed(2)))])]):_vm._e(),(
          _vm.referrer !== 'TaylorRose' &&
          /*!this.isCanopyUtilitiesOnly()  &&*/
          _vm.referrer !== 'AddressUpdater' &&
          _vm.referrer !== 'FamilyUpdater'
        )?_c('p',{staticClass:"fs-la mb-0 font-weigth-bold",staticStyle:{"font-size":"20px","font-weight":"600"}},[_vm._v("Unlimited Address Updates")]):_vm._e()],2),(_vm.referrer !== 'TaylorRose')?_c('div',{staticClass:"mover-bonus"},[(false)?_c('div',[_c('h3',{},[_vm._v(_vm._s(_vm.getCurrentMonth())+" Mover Bonus:")]),_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('img',{staticClass:"logo-timer me-2",attrs:{"src":require('@/assets/timer.png'),"alt":"","srcset":""}}),_c('h5',{staticClass:"fw-bold mb-0",staticStyle:{"color":"#ff7a7a"}},[_vm._v(" Expires: "),_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.counter))])])]),_c('p',{staticClass:"mb-4 fs-md"},[_vm._v(" If you use SlothMove after the bonus offer expires, you are able to purchase these bonuses individually ")])]):_vm._e(),_c('div',{staticClass:"checkboxes mb-4"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('p-check',{staticClass:"pretty p-icon p-curve p-thick p-jelly stay-join",staticStyle:{"font-size":"22px"},attrs:{"name":"check","color":"primary","value":_vm.campaignChecked},on:{"change":_vm.toggleCheckJoin}},[_c('div',{staticClass:"state"},[_c('i',{staticClass:"icon mdi mdi-check",staticStyle:{"margin-left":"-67%"}})])]),_c('p',{staticClass:"mb-0 ms-3 fs-xs c-pointer",on:{"click":function($event){return _vm.autoClickCheckbox('stay-join')}}},[_vm._v(" Stay in touch and join the SlothMovement.The latest deals, guides, tips and tricks to help you save money and get ahead 🦥 ")])],1)])]):_vm._e()]),(_vm.errorTryingSubmit)?_c('p',{staticClass:"fs-md mt-3",staticStyle:{"color":"rgb(198, 74, 69)"}},[_vm._v(" * Something went wrong. Please try again in a minute ")]):_vm._e(),(_vm.setPaymentStripe)?_c('OneOffPayment',{ref:"refStripeCheckout",attrs:{"email":_vm.email,"referrer":_vm.referrer,"priceOffered":_vm.priceOffered}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between"},[_c('h4',{staticClass:"fs-la mb-0 font-weigth-bold"},[_vm._v("Order Summary")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"margin-top":"10px"}},[_c('h5',{staticClass:"fs-sm",staticStyle:{"font-weight":"600"}},[_vm._v("Updating")])])
}]

export { render, staticRenderFns }